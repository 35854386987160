import React from 'react'

import Layout from '../../components/layout'
import SEO from "../../components/seo"
import "./contact.css"

export default () => (
  <Layout>
    <SEO title="Thanks" />
    <div className="home-wrapper">
      <div className="wrapper">
        <div className="flexed">
          <div>
            <img
              src="/images/davidsapp-logo.png"
              className="logo"
              alt="davidsapp logo"
            />
          </div>
          <h3>Thanks!</h3>
          <p className="centered">Maybe I'll get back to you. Maybe I won't</p>
        </div>
      </div>
    </div>
  </Layout>
)
